@media print {
    @page  {
        /*size: A4;*/
        /*margin: 20mm;*/
    }
    .print-container{
        margin: 10px;
        background: #ffffff;
    }
    p, th, td{
        font-size: 11pt;
    }
    
    .table-print {
        width: 100%;
        border-collapse: collapse;
    }

    .table-print, 
    .table-print th, 
    .table-print td {
        border: 1px solid;
    }
    .table-print th,
    .table-print td{
        padding: 2px 10px 2px 10px
    }

    .table-print th{
        text-align: center;
    }
    .table-print .text-right{
       text-align: right;
    }
}
/* No Print*/
.print-container{
    margin: 10px;
    background: #ffffff;
}
.print-content{
    padding: 20px
}
p, .table-print th, .table-print td{
    font-size: 11pt;
}

.table-print {
    width: 100%;
    border-collapse: collapse;
}

.table-print,
.table-print th,
.table-print td {
    border: 1px solid;
}
.table-print th,
.table-print td{
    padding: 2px 10px 2px 10px
}

.table-print th{
    text-align: center;
}
.table-print .text-right{
    text-align: right;
}
.header-title{
    text-align: center;
    text-underline-style: dash;
}

.header-section{
    margin-top: 0;
}

.header-section1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 30px;
}
p{
    margin-bottom: 5px !important;
}
.footer-section{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.box-signature{
    height: 80px;
}